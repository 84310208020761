import * as React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { GroupData, TagData } from './types';
import { functions } from '../../store';
import GroupCard from './GroupCard';
import ActiveUserPie from './ActiveUserPie';
import TopicBar from './TopicBar';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
const activeUserLabels = ['Active', 'Last Seen > 7 Days', 'Last Seen > 14 Days', 'Inactive'];
const registrationLabels = ['< 3 Days', '< 7 Days', '< 14 Days', '< 21 Days', '>= 21 Days'];
const userTypesLabels = ['Parents', 'Professionals', 'Under 12', 'Above 17'];
const userProgressLabels = ['Survey', '1_Module'];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
    },
    title: {
      fontWeight: 'bold',
      marginBottom: '8px',
      fontSize: '22px',
    },
    button: {
      color: 'white',
      fontSize: '12px',
    },
    alert: {
      fontSize: '14px',
    },
  }),
);

const addUserProgressLabels = () => {
  for (let i = 2; i <= 10; i++) {
    userProgressLabels.push(`${i}_Modules`);
  }
};

addUserProgressLabels();

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const firestore = useFirestore();
  const [psg, setPsg] = React.useState<GroupData[]>([]);
  const [tagLabels, setTagLabels] = React.useState<string[]>([]);
  const [tagData, setTagData] = React.useState<TagData[]>([]);
  const [tagChange, setTagChange] = React.useState<number>(0);
  const [activeUserData, setActiveUserData] = React.useState<number[]>([]);
  const [regData, setRegData] = React.useState<number[]>([]);
  const [userTypesData, setUserTypesData] = React.useState<number[]>([]);
  const [userProgressData, setUserProgressData] = React.useState<TagData[]>([]);
  const [alert, setAlert] = React.useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fetchFbData, setFetchFbData] = React.useState<boolean>(false);

  React.useEffect(() => {
    async function initialize() {
      setLoading(true);

      // Retrieve and set Facebook groups
      const groups = await firestore.collection('groups').get();

      const psgs: GroupData[] = [];
      groups?.docs.forEach(group => {
        const data = group.data();
        psgs.push({
          id: group.id,
          memberCount: data.member_count,
          memberRequestCount: data.member_request_count,
          name: data.name,
          privacy: data.privacy,
          noOfPosts: data.noOfPosts,
          noOfComments: data.noOfComments,
          icon: data.icon,
          createdAt: data.created_time,
        });
      });
      setPsg(psgs);

      // Get current identified topics for the topic bar chart
      const topicStats = await firestore.doc('config/topicStats').get();
      const topicsArray = topicStats.data()?.topicsArray;
      const topicSum = topicStats.data()?.topicSum;
      const topicSumPrevious = topicStats.data()?.topicSumPrevious;

      const topicLabels: string[] = [];
      const topicValues: number[] = [];
      if (topicsArray) {
        for (let i = 0; i < Math.min(5, topicsArray.length); i++) {
          topicLabels.push(topicsArray[i].topic);
          topicValues.push(topicsArray[i].occurrence);
        }
      }

      setTagLabels(topicLabels);
      setTagData([{ data: topicValues }]);
      setTagChange(topicSumPrevious !== 0 ? topicSum / topicSumPrevious : 1);

      // Get last seen statistics from config/meta
      const lastSeen = (await firestore.doc('config/lastSeen').get()).data();
      if (lastSeen) {
        const stats = lastSeen.statistics;
        setActiveUserData([stats.active, stats.moreThan7, stats.moreThan14, stats.inactive]);

        const reg = lastSeen.registration;
        setRegData([reg.last3Days, reg.last7Days, reg.last14Days, reg.last21Days, reg.other]);
      }

      // Get user types distribution
      const userTypes = (await firestore.doc('config/userTypes').get()).data();
      if (userTypes) setUserTypesData([userTypes.parents, userTypes.professional, userTypes.under12, userTypes.over17]);

      // Get user progress statistics
      const userProgress = (await firestore.doc('config/userProgress').get()).data();
      if (userProgress) {
        const tmpProgressData: number[] = [userProgress.survey];
        for (let i = 1; i <= 10; i++) tmpProgressData.push(userProgress[`${i}_module`]);
        setUserProgressData([{ data: tmpProgressData }]);
      }

      setLoading(false);
    }

    initialize();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
  };

  const fetchData = async () => {
    setFetchFbData(true);

    const result: any = await functions.addGroupPostsAdminFn();
    setAlert(result.data.message);
    if (result.data.status === 200) setSeverity('success');
    else setSeverity('error');
    setAlertOpen(true);

    setFetchFbData(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h6">
            User Statistics
          </Typography>
        </Grid>

        <Grid item xs={12} container spacing={2} style={{ marginBottom: '8px' }}>
          <Grid item xs={12} md={6} lg={3}>
            <ActiveUserPie
              title="Active Users"
              subtitle="No. of Inactive Users"
              data={activeUserData}
              highlight={activeUserData[3]}
              labels={activeUserLabels}
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <ActiveUserPie
              title="New Registration"
              subtitle="No. of Registrations"
              data={regData}
              highlight={regData.reduce((a, b) => a + b, 0)}
              labels={registrationLabels}
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <ActiveUserPie
              title="User Types"
              subtitle="No. of Parents"
              data={userTypesData}
              highlight={userTypesData[0]}
              labels={userTypesLabels}
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TopicBar
              data={userProgressData}
              title="User Progress"
              tooltip="Participants"
              labels={userProgressLabels}
              loading={loading}
              horizontal={true}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.title} variant="h6">
            Peer Support Groups
          </Typography>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            {fetchFbData ? (
              <CircularProgress size={28} />
            ) : (
              <Button variant="contained" color="primary" onClick={fetchData} className={classes.button}>
                Fetch Facebook Data
              </Button>
            )}
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <TopicBar
              data={tagData}
              title="Trending Topics"
              subtitle="Topics Than Last 24 Hours"
              tooltip="Occurrences"
              labels={tagLabels}
              tagChange={tagChange}
              loading={loading}
              horizontal={true}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={8}>
            <GridList className={classes.gridList} cellHeight="auto" cols={1}>
              {psg.map(data => {
                return (
                  <Grid key={data.name} item xs={12}>
                    <GroupCard cardData={data} />
                  </Grid>
                );
              })}
            </GridList>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar open={alertOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" className={classes.alert}>
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Dashboard;
