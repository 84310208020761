import * as React from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TopicBarProps } from './types';
import { BaseOptionChart } from '../charts';
import { colors } from '../../styles/variables';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      borderRadius: '20px',
      height: '100%',
    },
    chartTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    subheader: {
      fontSize: '14px',
      height: '32px',
      color: colors.gray.calm,
    },
    increase: {
      display: 'inline',
      fontSize: '22px',
      color: colors.success,
    },
    decrease: {
      fontSize: '22px',
      display: 'inline',
      color: colors.warning,
    },
    barContainer: {
      minHeight: '300px',
      maxHeight: '300px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  }),
);

export default function TopicBar(props: TopicBarProps) {
  const classes = useStyles();

  const formatLabels = () => {
    const formattedLabels = [];
    for (let i = 0; i < props.labels.length; i++) {
      formattedLabels.push(props.labels[i].split(' '));
    }
    return formattedLabels;
  };

  const chartOptions: any = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: any) => seriesName,
        title: {
          formatter: () => `# ${props.tooltip}`,
        },
      },
    },
    plotOptions: {
      bar: { horizontal: props.horizontal, barHeight: '40%', borderRadius: 2 },
    },
    xaxis: {
      categories: formatLabels(),
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
    },
  });

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography className={classes.chartTitle} variant="h6">
            {props.title}
          </Typography>
        }
        subheader={
          <Typography className={classes.subheader} variant="h6">
            {props.tagChange && (
              <Typography className={props.tagChange >= 0 ? classes.increase : classes.decrease}>{`${
                props.tagChange >= 0 ? '+' : ''
              }${Math.round(props.tagChange * 100)}%`}</Typography>
            )}
            {props.subtitle && ` ${props.subtitle}`}
          </Typography>
        }
      />
      <CardContent className={classes.barContainer}>
        {props.loading ? (
          <Box style={{ height: '272px' }} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <ReactApexChart type="bar" series={props.data} options={chartOptions} height="100%" />
        )}
      </CardContent>
    </Card>
  );
}
