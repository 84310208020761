import { Link } from 'gatsby';
import styled from '@emotion/styled';

export const ActionLink = styled.a`
  text-decoration: none;
  background-image: none;
`;

export const RouteLink = styled(Link)`
  text-decoration: none;
  background-image: none;
`;
