import * as React from 'react';
import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import { PrivateRoute } from '../components/private';
import { Dashboard } from '../components/dashboard';

const MainDashboard = () => (
  <IndexLayout>
    <PrivateRoute>
      <Page>
        <Container>
          <Dashboard />
        </Container>
      </Page>
    </PrivateRoute>
  </IndexLayout>
);

export default MainDashboard;
