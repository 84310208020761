import * as React from 'react';
import clsx from 'clsx';
import { colors } from '../../styles/variables';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ForumIcon from '@material-ui/icons/Forum';
import ChatIcon from '@material-ui/icons/Chat';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { GroupCardProps } from './types';
import { ActionLink, RouteLink } from './styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '320px',
      height: '100%',
      borderRadius: '20px',
    },
    memberColor: {
      fill: '#66bb6a',
    },
    requestColor: {
      fill: '#ef5350',
    },
    postsColor: {
      fill: '#26c6da',
    },
    commentsColor: {
      fill: '#c5afda',
    },
    iconBtn: {
      color: colors.brand,
      fontSize: '28px',
    },
    box: {
      textAlign: 'center',
      border: 'solid 1px #e1e5e8',
      borderRadius: '16px',
    },
    description: {
      fontSize: '14px',
    },
    actionBtn: {
      '&&&:before': {
        textDecoration: 'none',
      },
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    subheader: {
      fontSize: '14px',
      color: colors.gray.calm,
    },
  }),
);

function GroupCard(props: GroupCardProps) {
  const classes = useStyles();
  const { id, name, icon, createdAt, noOfComments, noOfPosts, memberCount, memberRequestCount } = props.cardData;

  return (
    <Card className={classes.root}>
      <CardHeader
        style={{ height: '100px' }}
        avatar={<Avatar alt="Group" src={icon}></Avatar>}
        action={
          <RouteLink to={`/psg`} onClick={() => localStorage.setItem('groupId', id)}>
            <Tooltip title="View Group Details">
              <IconButton className={classes.actionBtn} color="primary" aria-label="view group details">
                <FindInPageIcon />
              </IconButton>
            </Tooltip>
          </RouteLink>
        }
        title={
          <Typography className={classes.title} variant="h6">
            {name}
          </Typography>
        }
        subheader={
          <Typography className={classes.subheader} variant="body2">
            {createdAt.split('T')[0]}
          </Typography>
        }
      />

      <CardContent>
        <Grid container spacing={2} justify="center">
          <Grid item xs={5} className={classes.box}>
            <ForumIcon className={clsx(classes.iconBtn, classes.postsColor)} />
            <Typography variant="h5">{noOfPosts}</Typography>
            <p className={classes.description}>Posts</p>
          </Grid>

          <Grid item xs={1}></Grid>

          <Grid item xs={5} className={classes.box}>
            <ChatIcon className={clsx(classes.iconBtn, classes.commentsColor)} />
            <Typography variant="h5">{noOfComments}</Typography>
            <p className={classes.description}>Comments</p>
          </Grid>

          <Grid item xs={5} className={classes.box} style={{ marginTop: '20px' }}>
            <AssignmentIndIcon className={clsx(classes.iconBtn, classes.memberColor)} />
            <Typography variant="h5">{memberCount}</Typography>
            <p className={classes.description}>Members</p>
          </Grid>

          <Grid item xs={1}></Grid>

          <Grid item xs={5} className={classes.box} style={{ marginTop: '20px' }}>
            <GroupAddIcon className={clsx(classes.iconBtn, classes.requestColor)} />
            <Typography variant="h5">{memberRequestCount}</Typography>
            <p className={classes.description}>Membership Requests</p>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <ActionLink href={`https://www.facebook.com/groups/${id}`} target="_blank">
          <Tooltip title="Vist Facebook Group">
            <IconButton aria-label="visit group">
              <FacebookIcon style={{ fill: '#4267B2' }} />
            </IconButton>
          </Tooltip>
        </ActionLink>

        <ActionLink href={`https://www.facebook.com/groups/${id}/members`} target="_blank">
          <Tooltip title="View Group Members">
            <IconButton aria-label="view members">
              <AssignmentIndIcon style={{ fill: '#66bb6a' }} />
            </IconButton>
          </Tooltip>
        </ActionLink>

        <ActionLink href={`https://www.facebook.com/groups/${id}/member-requests`} target="_blank">
          <Tooltip title="Approve Membership Requests">
            <IconButton aria-label="view membership requests">
              <GroupAddIcon style={{ fill: '#ef5350' }} />
            </IconButton>
          </Tooltip>
        </ActionLink>
      </CardActions>
    </Card>
  );
}

export default GroupCard;
