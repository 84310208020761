import * as React from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { ActiveUserPieProps } from './types';
import { RouteLink } from './styles';
import { BaseOptionChart } from '../charts';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../styles/variables';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import PeopleIcon from '@material-ui/icons/People';

const CHART_HEIGHT = 272;
const LEGEND_HEIGHT = 72;

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      borderRadius: '20px',
      height: '100%',
    },
    subheader: {
      fontSize: '14px',
      color: colors.gray.calm,
    },
    highlight: {
      display: 'inline',
      fontSize: '22px',
      color: colors.success,
    },
    warning: {
      fontSize: '22px',
      display: 'inline',
      color: colors.warning,
    },
    chartTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    chart: {
      height: CHART_HEIGHT,
      marginTop: '0',
      '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
      '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible',
      },
      '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: 'solid 1px #e1e5e8',
        marginTop: '18px',
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
      },
    },
  }),
);

export default function ActiveUserPie(props: ActiveUserPieProps) {
  const classes = useStyles();
  const theme = useTheme();

  const chartOptions: any = merge(BaseOptionChart(), {
    colors: [theme.palette.primary.main, theme.palette.info.main, theme.palette.warning.main, theme.palette.error.main],
    labels: props.labels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: any) => seriesName,
        title: {
          formatter: (seriesName: any) => `# ${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography className={classes.chartTitle} variant="h6">
            {props.title}
          </Typography>
        }
        subheader={
          <Typography className={classes.subheader} variant="h6">
            {props.subtitle + ': '}
            <Typography className={props.title === 'Active Users' ? classes.warning : classes.highlight}>
              {props.highlight}
            </Typography>
          </Typography>
        }
        action={
          <RouteLink to="/user">
            <Tooltip title="Manage Users">
              <IconButton color="primary" aria-label="manager users">
                <PeopleIcon />
              </IconButton>
            </Tooltip>
          </RouteLink>
        }
      />
      <CardContent>
        {props.loading ? (
          <Box style={{ height: CHART_HEIGHT }} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <ReactApexChart
            className={classes.chart}
            type="pie"
            series={props.data}
            options={chartOptions}
            height={220}
          />
        )}
      </CardContent>
    </Card>
  );
}
